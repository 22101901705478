<template>
  <div :class="isMourn == 'Y' ? 'footer filter' : 'footer'">
    <div class="footerInfo">
      <div class="nav">
        <h4 v-if="language == 'zh'">网站导航</h4>
        <h4 v-if="language == 'en'">Site navigation</h4>
        <ul>
          <li v-for="(item, index) in siteNav" :key="index">
            <el-link :href="item.link" rel="noreferrer" target="_blank" :underline="false">
               <span v-if="language == 'zh'">{{ item.title }}</span>
               <span v-if="language == 'en'">{{ item.enTitle }}</span>
            </el-link>
          </li>
        </ul>
      </div>
      <div class="nav">
        <h4 v-if="language == 'zh'">关于我们</h4>
        <h4 v-if="language == 'en'">About us</h4>
        <ul v-if="language == 'zh'">
          <li v-for="(item,index) in aboutList" :key="index">
            <el-link :href="item.link" rel="noreferrer"
              target="_blank" :underline="false">
              {{item.title}}
            </el-link>
          </li>
        </ul>
        <ul v-if="language == 'en'">
          <li v-for="(item,index) in aboutList2" :key="index">
            <el-link :href="item.link" rel="noreferrer"
              target="_blank" :underline="false">
              {{item.title}}
            </el-link>
          </li>
        </ul>
      </div>
      <div class="nav" v-if="language == 'zh'">
        <h4>联系我们</h4>
        <div>
          <p>0431-89158000</p>
          <p>邮编：130013</p>
          <p>邮箱：china_haoyue@163.com</p>
          <p>吉林省长春市绿园区皓月大路11111号</p>
        </div>
      </div>
      <div class="nav" v-if="language == 'en'">
        <h4>Contact us</h4>
        <div>
          <p>0431-89158000</p>
          <p>Zip code：130013</p>
          <p>E-mail：china_haoyue@163.com</p>
          <p>No.11111, Haoyue Road, Lvyuan District, Changchun, Jilin Province</p>
        </div>
      </div>
      <div class="nav">
        <h4 class="ydq" v-if="language == 'zh'">关注我们</h4>
        <h4 class="ydq" v-if="language == 'en'">Follow us</h4>
        <div class="code ydq">
          <el-image class="codePhoto" :src="codeSrc" :preview-src-list="[codeSrc]"/>
        </div>
        <p class="weixi ydq" v-if="language == 'zh'">皓月官方微信</p>
        <p class="weixi ydq" v-if="language == 'en'">Haoyue official wechat</p>
      </div>
    </div>
    <div class="copyRight">
      <p v-if="language == 'zh'">
        <!-- <el-link href="https://beian.mps.gov.cn/#/query/webSearch?code=这里是备案号" rel="noreferrer" target="_blank" :underline="false"> -->
        吉ICP备14004422号
        <!-- </el-link> -->
        1 © 2020 吉林省长春皓月清真肉业股份有限公司
      </p>
      <p v-if="language == 'en'">
        <!-- <el-link href="https://beian.mps.gov.cn/#/query/webSearch?code=这里是备案号" rel="noreferrer" target="_blank" :underline="false"> -->
          Ji ICP No. 14004422 1 © 2020 Changchun Haoyue Halal Meat Industry Co., LTD
        <!-- </el-link> -->
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  data() {
    return {
      language: "",
      codeSrc: require('../assets/images/qrCode.png'),
      siteNav: [
        {
          title: "招商加盟",
          enTitle:"Merchants join",
          link: "http://www.china-haoyue.com/zsjm.html"
        },
        // {
        //   title: "皓月新闻",
        //   enTitle:"Haoyue news",
        //   link: "http://www.china-haoyue.com/e/action/ListInfo/?classid=3"
        // },
        {
          title: "皓月牧场",
          enTitle:"Haoyue ranch",
          link: "http://www.china-haoyue.com/zsjm.html"
        },
        // {
        //   title: "活动纪实",
        //   enTitle:"Activity record",
        //   link: "http://www.china-haoyue.com/e/action/ListInfo/?classid=5"
        // },
        {
          title: "皓月文旅",
          enTitle:"Haoyue resort",
          link: "http://www.china-haoyue.com/hycf.html"
        },
        // {
        //   title: "领导关怀",
        //   enTitle:"Leadership care",
        //   link: "http://www.china-haoyue.com/e/action/ListInfo/?classid=4"
        // },
        {
          title: "产品中心",
          enTitle:"Product center",
          link: "http://www.china-haoyue.com/fgt.html"
        },
        // {
        //   title: "诚聘英才",
        //   enTitle:"Join us",
        //   link: "http://www.china-haoyue.com/e/action/ShowInfo.php?classid=1&id=100"
        // }
      ],

      aboutList: [
        {
          title: "企业简介",
          link: "http://www.china-haoyue.com/e/action/ShowInfo.php?classid=1&id=1"
        },
        {
          title: "企业荣誉",
          link: "http://www.china-haoyue.com/e/action/ListInfo/?classid=40"
        },
        {
          title: "皓月人文",
          link: "http://www.china-haoyue.com/e/action/ListInfo/?classid=50"
        },
        {
          title: "大事纪实",
          link: "http://www.china-haoyue.com/dsj.html"
        }
      ],
      aboutList2: [
      {
          title: "Enterprise profile",
          link: "http://www.china-haoyue.com/e/action/ShowInfo.php?classid=1&id=1"
        },
        {
          title: "Enterprise honor",
          link: "http://www.china-haoyue.com/e/action/ListInfo/?classid=40"
        },
        {
          title: "Haoyue humanistic care",
          link: "http://www.china-haoyue.com/e/action/ListInfo/?classid=50"
        },
        {
          title: "Big events",
          link: "http://www.china-haoyue.com/dsj.html"
        }
      ]
    }
  },
  computed: {
    isMourn() {      
      return this.$store.getters.getPageMourn;
    }
  },
  mounted() {
    this.language = localStorage.getItem("language")
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 430px;
  background: #FF7E14;
  margin: 0 auto;
  padding: 72px 0;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
  &.filter {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -webkit-filter: grayscale(1);
  }
}
.footerInfo {
  width: 1320px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}

.nav {
  width: 25%;
  text-align: left;
}

.nav h4 {
  font-size: 24px;
  color: #ffffff;
}

.nav ul {
  width: 180px;
  list-style-type: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav ul li {
  min-width: 206px !important;
  margin: 0 0 16px 0;
  font-size: 18px;
  color: #ffffff;
}

.nav div p {
  font-size: 18px;
  color: #ffffff;
}

.code {
  margin: 0 0 0 0 // width: 104px;
    // height: 104px;
    // text-align: center;
    // background: #6F6F6F;
    //border: 6px solid #ffffff;
}

.weixi {
  font-size: 18px;
  color: #ffffff;
  margin: 10px 0 0 10px;
}

.ydq {
  margin-left: 156px;
}

.copyRight {
  width: 100%;
  height: 64px;
  background: #E67112;
  text-align: center;
  padding: 30px auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.copyRight p {
  font-size: 18px;
  color: #ffffff;
}

a {
  font-size: 18px;
  color: #ffffff !important;
  text-decoration: none;
}


.codePhoto{
  width: 114px;
  height: 114px;
}
</style>